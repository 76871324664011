import axios from "axios";
import { supabase } from "./utils";
export const getCommunityById = async community_id => {
  try {
    const res = await axios.get(
      `https://api.leasemagnets.com/magnets?queryByCommunity=${community_id}`
    );
    return res;
  } catch (error) {
    console.log("errr======>", error);
  }
};

export const getCommunitiesByUserId = async user_id => {
  try {
    const res = await axios.get(
      `https://api.leasemagnets.com/communities/get?uid=${user_id}` //OeKIJ0yGtbMDgJrMrCi9uIDB32U2
    );
    // console.log("communities TYG: ", res?.data?.communities || []);
    return res?.data?.communities || [];
  } catch (error) {
    return [];
  }
};

export async function getLeadsByuuid(uuid) {
  let supabaseResponse = await supabase
    .from("Lead")
    .select("*")
    .eq("magnet_uuid", uuid)
    .order("time", { ascending: false });
  return supabaseResponse;
}

export const getScreenList = async (
  community_id,
  skip_update_community_name
) => {
  try {
    let res = await getCommunityById(community_id);
    console.log("magnets!", res);
    let magnets = res?.data?.magnets;
    window.communityName = magnets[0]?.name;
    window.uuid = magnets[0]?.uuid;
    if (window.updatecommunityName && magnets[0]?.name) {
      if (!skip_update_community_name) {
        window.updatecommunityName(magnets[0]?.name);
      }
    }
    let category_keys = magnets[0].magnet_details?.template?.category_keys;
    let screens = [];
    let categories = magnets[0].magnet_details?.template?.categories;
    category_keys.map(item => {
      let category_obj = categories[item];
      if (item !== "template") {
        category_obj.screen_keys.map(item2 =>
          screens.push(category_obj.screens[item2])
        );
      }
      return true;
    });
    return screens;
  } catch (error) {
    console.log("errr======>", error);
  }
};
