import { Fragment, useEffect, useState } from "react";
import { useMedia } from "react-use";
import {
  selectAppData,
  selectIsConnectedToRoom,
  selectPermissions,
  selectSessionId,
  useHMSActions,
  useHMSStore,
  useRecordingStreaming,
} from "@100mslive/react-sdk";
import { EndStreamIcon, RecordIcon } from "@100mslive/react-icons";
import {
  Box,
  Button,
  config as cssConfig,
  Flex,
  Loading,
  Popover,
  Text,
  Tooltip,
} from "@100mslive/react-ui";
import GoLiveButton from "../GoLiveButton";
import { ResolutionInput } from "../Streaming/ResolutionInput";
import { getResolution } from "../Streaming/RTMPStreaming";
import { ToastManager } from "../Toast/ToastManager";
import { AdditionalRoomState, getRecordingText } from "./AdditionalRoomState";
import { useSidepaneToggle } from "../AppData/useSidepane";
import { useSetAppDataByKey } from "../AppData/useUISettings";
import { getDefaultMeetingUrl,update_email_by_sessionid,get_user_email_by_uid,get_row_by_sessionId } from "../../common/utils";
import {
  APP_DATA,
  RTMP_RECORD_DEFAULT_RESOLUTION,
  SIDE_PANE_OPTIONS,
} from "../../common/constants";
import { WithContext as ReactTags } from 'react-tag-input';
import './StreamActions.css'

export const LiveStatus = () => {
  const { isHLSRunning, isRTMPRunning } = useRecordingStreaming();
  if (!isHLSRunning && !isRTMPRunning) {
    return null;
  }
  return (
    <Flex align="center">
      <Box css={{ w: "$4", h: "$4", r: "$round", bg: "$error", mr: "$2" }} />
      <Text>
        Live
        <Text as="span" css={{ "@md": { display: "none" } }}>
          &nbsp;with {isHLSRunning ? "HLS" : "RTMP"}
        </Text>
      </Text>
    </Flex>
  );
};

export const RecordingStatus = () => {
  const {
    isBrowserRecordingOn,
    isServerRecordingOn,
    isHLSRecordingOn,
    isRecordingOn,
  } = useRecordingStreaming();
  const permissions = useHMSStore(selectPermissions);

  if (
    !isRecordingOn ||
    // if only browser recording is enabled, stop recording is shown
    // so no need to show this as it duplicates
    [
      permissions?.browserRecording,
      !isServerRecordingOn,
      !isHLSRecordingOn,
      isBrowserRecordingOn,
    ].every(value => !!value)
  ) {
    return null;
  }
  return (
    <Tooltip
      title={getRecordingText({
        isBrowserRecordingOn,
        isServerRecordingOn,
        isHLSRecordingOn,
      })}
    >
      <Box
        css={{
          color: "$error",
        }}
      >
        <RecordIcon width={24} height={24} />
      </Box>
    </Tooltip>
  );
};

const EndStream = () => {
  const { isStreamingOn } = useRecordingStreaming();
  const toggleStreaming = useSidepaneToggle(SIDE_PANE_OPTIONS.STREAMING);
  if (!isStreamingOn) {
    return null;
  }
  return (
    <Button
      data-testid="end_stream"
      variant="standard"
      outlined
      icon
      onClick={() => {
        toggleStreaming();
      }}
    >
      <EndStreamIcon />
      End Stream
    </Button>
  );
};

const StartRecording = () => {
  const permissions = useHMSStore(selectPermissions);
  const recordingUrl = useHMSStore(selectAppData(APP_DATA.recordingUrl));
  const [resolution, setResolution] = useState(RTMP_RECORD_DEFAULT_RESOLUTION);
  const [open, setOpen] = useState(false);
  const [recordingStarted, setRecordingState] = useSetAppDataByKey(
    APP_DATA.recordingStarted
  );
  const { isBrowserRecordingOn, isStreamingOn, isHLSRunning } =
    useRecordingStreaming();
  const hmsActions = useHMSActions();
  if (!permissions?.browserRecording || isHLSRunning) {
    return null;
  }
  

  const sessionId = useHMSStore(selectSessionId);
  const [tags, setTags] = React.useState([]
  //   [
  //   { id: '1', text: 'parmar.amulya@gmail.com' },
  //   { id: '2', text: 'blessed.master.amulya@gmail.com' },
  // ]
  );
  //console.log("sessionId",sessionId)

  const fetch_user_email = async () => {
    let otherEmails = []
    if(sessionId) {
      console.log("sessionId",sessionId)
      let row  = await get_row_by_sessionId(sessionId)
      if(row?.recordings?.[0]) {
        otherEmails = [...otherEmails, row?.recordings?.[0]?.emailsToSend]
        setTag([...otherEmails])
      }
      else {
        let res = await get_user_email_by_uid(window.uid)
        if(res.user?.email) {
          otherEmails = [...otherEmails,  { id: otherEmails.length +1, text: res.user?.email }]
          setTags([...otherEmails])
        }
      } 
    }
    if (window?.emails) {
      let emails = window.emails.split(",")
      let newTags = emails.map((email, idx) => ({ id: tags.length +1 + idx, "text": email }));
      otherEmails = [...otherEmails, ...newTags]
      setTags([ ...otherEmails])
    }
  }

  useEffect(() => {
    fetch_user_email()
  },[sessionId])

  const handleDelete = i => {
    if (confirm("Are you sure you want to delete email?")) {
      setTags(tags.filter((tag, index) => index !== i));
    }
  };

  const handleAddition = tag => {
    console.log("handleAddition",handleAddition)
    setTags([...tags, tag]);
  };

  useEffect(()=> {
    if (tags) {
      update_email_by_sessionid(sessionId,tags)
    }
    
    window.emailRecordingContext = {
      sessionId: sessionId,
      community_id: window.cid,
      community_name: window.communityName,
      uid: window.uid, 
      emails: tags
    }
  },[tags])

  if (window.emailRecordingContext) {
    console.log('emailRecordingcontext TYG: ', window.emailRecordingContext)
  }

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = index => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const KeyCodes = {
    comma: 188,
    enter: 13
  };
  
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  if (isBrowserRecordingOn) {
    return (
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>


          <Button
            variant="danger"
            data-testid="stop_recording"
            icon
            outlined
            onClick={() => {
              setOpen(true)
              // ToastManager.addToast({
              //   title: "Recording successful. Sending the email",
              //   variant: "success",
              // });
            }}
          >
            <RecordIcon />
            <Text
              as="span"
              css={{ "@md": { display: "none" }, color: "currentColor" }}
            >
              Stop Recording
            </Text>
          </Button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content align="end" sideOffset={8} css={{ w: "$64" }}>
            <Text variant="body" css={{ color: "$textMedEmp" }}>
              Are you sure you want to end the recording?

              {/* {recordingUrl} {sessionId} */}
              <br />
            </Text>
            <span style={{color:"white", fontSize: '9px'}}>Which email(s) should we send recording to?</span>

            <div style={{marginBottom: "15px", marginTop: "15px"}}>
            <ReactTags 
              placeholder="Press enter email"
              tags={tags}
              // suggestions={suggestions}
              delimiters={delimiters}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              handleDrag={handleDrag}
              handleTagClick={handleTagClick}
              inputFieldPosition="bottom"
              autocomplete
            />
            </div>

            <Button
              data-testid="stop_recording_confirm"
              variant="danger"
              icon
              css={{ ml: "auto" }}
              onClick={async () => {
                try {
                  await hmsActions.stopRTMPAndRecording();
                  ToastManager.addToast({
                    title: "Recording successful. Sending the email",
                    variant: "success",
                  });
                  //await update_email_by_sessionid(sessionId)
                  // ToastManager.addToast({
                  //   title: "Email sent",
                  //   variant: "success",
                  // });
                } catch (error) {
                  ToastManager.addToast({
                    title: error.message,
                    variant: "error",
                  });
                }
                setOpen(false);
              }}
            >
              Stop
            </Button>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  }
  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <Button
          data-testid="start_recording"
          variant="standard"
          icon
          disabled={recordingStarted || isStreamingOn}
          onClick={async () => { 
            setOpen(true);
            // ToastManager.addToast({
            //   title: "Recording successful. Sending the email",
            //   variant: "success",
            // });
            // await update_email_by_sessionid(sessionId)
          }}
        >
          {recordingStarted ? (
            <Loading size={24} color="currentColor" />
          ) : (
            <RecordIcon />
          )}
          <Text
            as="span"
            css={{ "@md": { display: "none" }, color: "currentColor" }}
          >
            {recordingStarted ? "Starting" : "Start"} Recording
          </Text>
        </Button>
      </Popover.Trigger>
      <Popover.Content align="end" sideOffset={8} css={{ w: "$64" }}>
        <ResolutionInput
          testId="recording_resolution"
          css={{ flexDirection: "column", alignItems: "start" }}
          onResolutionChange={setResolution}
        />
              {/* {recordingUrl} {sessionId} <br /> */}
          <span style={{color:"white", fontSize: '9px'}}>Which email(s) should we send recording to?</span>
          <div style={{gap:"4px", marginBottom: "15px"}}>
          <ReactTags 
            placeholder="Press enter email"
            tags={tags}
            // suggestions={suggestions}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition="bottom"
            autocomplete
          />
          </div>
        <Button
          data-testid="start_recording_confirm"
          variant="primary"
          icon
          css={{ ml: "auto" }}
          type="submit"
          disabled={recordingStarted || isStreamingOn}
          onClick={async () => {
            try {
              setRecordingState(true);
              await hmsActions.startRTMPOrRecording({
                meetingURL: recordingUrl || getDefaultMeetingUrl(),
                resolution: getResolution(resolution),
                record: true,
              });
            } catch (error) {
              if (error.message.includes("stream already running")) {
                ToastManager.addToast({
                  title: "Recording already running",
                  variant: "error",
                });
              } else {
                ToastManager.addToast({
                  title: error.message,
                  variant: "error",
                });
              }
              setRecordingState(false);
            }
            setOpen(false);
          }}
        >
          Start
        </Button>
      </Popover.Content>
    </Popover.Root>
  );
};

export const StreamActions = () => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const permissions = useHMSStore(selectPermissions);
  const isMobile = useMedia(cssConfig.media.md);
  return (
    <Flex align="center" css={{ gap: "$4" }}>
      <AdditionalRoomState />
      <Flex align="center" css={{ gap: "$4", "@md": { display: "none" } }}>
        <LiveStatus />
        <RecordingStatus />
      </Flex>
      {isConnected && !isMobile ? <StartRecording /> : null}
      {isConnected && (permissions.hlsStreaming || permissions.rtmpStreaming) && (
        <Fragment>
          <GoLiveButton />
          <EndStream />
        </Fragment>
      )}
    </Flex>
  );
};
