import React from "react";
import {
  config as cssConfig,
  Flex,
  VerticalDivider,
} from "@100mslive/react-ui";
import { Logo, SpeakerTag } from "./HeaderComponents";
import { CommunityAvailabilityList, ParticipantCount } from "./ParticipantList";
import { StreamActions } from "./StreamActions";
import { useMedia } from "react-use";
import { useEffect, useState } from "react";

export const ConferencingHeader = ({ isPreview }) => {
  const isMobile = useMedia(cssConfig.media.md);
  const [communtiyname, setcommunityname] = useState("");

  const updatecommunityName = community_name => {
    setcommunityname(community_name);
  };
  useEffect(() => {
    window.updatecommunityName = updatecommunityName;
    return () => {
      window.updatecommunityName = undefined;
    };
  }, []);
  return (
    <Flex
      justify="between"
      align="center"
      css={{ position: "relative", height: "100%" }}
    >
      <Flex align="center" css={{ position: "absolute", left: "$10" }}>
        <Logo />
        <VerticalDivider css={{ ml: "$8" }} />
        {!isPreview ? <SpeakerTag /> : null}
      </Flex>

      <Flex
        align="center"
        css={{
          position: "absolute",
          right: "$10",
          gap: "$4",
        }}
      >
        <StreamActions />
        {!window.minimal && (
          <>
            <CommunityAvailabilityList communtiyname={communtiyname} />
            <ParticipantCount />
          </>
        )}
      </Flex>
    </Flex>
  );
};
