// @ts-nocheck
import { useEffect, useState, useRef } from "react";
import { Device } from "@twilio/voice-sdk";

// import {
//   useConvoStore,
//   useContactStore,
// } from "@/utils/store/conversationStore";

import toast from "react-hot-toast";
import moment from "moment";

import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { getSupabaseTable,supabase } from "../../utils/utils";

import { ArrowDownIcon, ArrowUp } from "lucide-react";
import { Box } from "@100mslive/react-ui";
import Flag from 'react-world-flags';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import { codeMappings } from "../../common/utils";
import axios from "axios";
import {  useHMSStore } from "@100mslive/react-sdk";

// //Types
const USER_STATE = {
  CONNECTING : "Connecting",
  READY : "Ready",
  ON_CALL : "On call",
  INCOMING : "Incoming",
  OFFLINE : "Offline",
}

const numberList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "0", "#"];

const stateColor = {
  [USER_STATE.CONNECTING]: "#B7AC44",
  [USER_STATE.INCOMING]: "#B7AC44",
  [USER_STATE.READY]: "#DAD870",
  [USER_STATE.ON_CALL]: "#FF5C4D",
  [USER_STATE.OFFLINE]: "#FFB52E",
};


export function formatTime(timeInSeconds, simplify) {
  const days = Math.floor(timeInSeconds / 86400);
  const hours = Math.floor((timeInSeconds % 86400) / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  let formattedTime = "";
  if (days > 0) {
    formattedTime += `${days} d`;
  }
  if (hours > 0) {
    formattedTime += ` ${hours} h`;
    if (simplify && days > 0) {
      return formattedTime;
    }
  }
  if (minutes > 0) {
    formattedTime += ` ${minutes} m`;
    if (simplify && hours > 0) {
      return formattedTime;
    }
  }
  if (seconds > 0) {
    formattedTime += ` ${seconds} s`;
    if (simplify && minutes > 0) {
      return formattedTime;
    }
  }

  return formattedTime;
}
// export async function addConvoHistory(convo_id, historyObj) {
//   try {
//     const { data, error } = await supabase
//       .from("conversations")
//       .select("*")
//       .eq("convo_id", convo_id);

//     if (error) {
//       throw new Error(error.message);
//     }

//     const convoObj = data ? data[0] : null;
//     const convoHistoryArr = convoObj?.convo_history || [];

//     convoHistoryArr.push(historyObj);

//     const { error: upsertError } = await supabase.from("conversations").upsert([
//       {
//         convo_id: convo_id,
//         convo_history: convoHistoryArr,
//       },
//     ]);
//     console.log("inserted");
//     if (upsertError) {
//       throw new Error(upsertError.message);
//     }

//     return "History added successfully"; // You can return a more meaningful response
//   } catch (error) {
//     return error.message; // Handle any errors
//   }
// }

// export async function updateConvoHistory(
//   convo_id,
//   historyId,
//   newHistoryElements,
// ) {
//   const { data: convoObjArr, error } = await supabase
//     .from("conversations")
//     .select("*")
//     .eq("convo_id", convo_id);
//   let convoObj = convoObjArr?.[0];
//   if (error) {
//     console.error("Error fetching conversation:", error.message);
//     return;
//   }

//   const convoHistory = convoObj.convo_history || [];
//   let indexToUpdate = -1;
//   let historyObj = {};
//   console.log("convoObjArr", convoObjArr);
//   console.log("historyId", historyId);
//   console.log("historyObj", historyObj);
//   for (let index = convoHistory.length - 1; index >= 0; index--) {
//     const history = convoHistory[index];

//     if (history?.id?.includes(historyId)) {
//       console.log(
//         "FOUND!: ",
//         convoHistory.length - index - 1,
//         history.id,
//         historyId,
//       );
//       indexToUpdate = index;
//       historyObj = history;
//       break;
//     } else {
//       console.log("item not found: ", index, history.id);
//     }
//   }

//   console.log("indexToUpdate", indexToUpdate);

//   // End early if no history found
//   if (indexToUpdate === -1) {
//     return;
//   }

//   convoObj.convo_history[indexToUpdate] = {
//     ...historyObj,
//     ...newHistoryElements,
//   };

//   const { data: result, error: upsertError } = await supabase
//     .from("conversations")
//     .upsert([
//       {
//         convo_id: convo_id,
//         convo_history: convoObj?.convo_history,
//       },
//     ]);

//   if (upsertError) {
//     console.error("Error upserting conversation:", upsertError.message);
//   }

//   return result;
// }

//Helpers
const Timer = () => {
  const [timer, setTimer] = useState({ mins: 0, sec: 0 });
  const getTime = () => {
    setTimer((state) => ({
      mins: state.sec === 60 ? state.mins + 1 : state.mins,
      sec: state.sec === 60 ? 0 : state.sec + 1,
    }));
  };
  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timer text-black">
      {`${timer.mins < 9 ? "0" + timer.mins : timer.mins} : ${
        timer.sec < 9 ? "0" + timer.sec : timer.sec
      }`}
    </div>
  );
};

//Components
const Phone = ({
  //token,
  setPhoneNumber,
  phoneNumber
}) => {
    // Function to get init token
  const [token, setToken] = useState("");

  const baseURL = "https://chat.scaleconvo.com";
  // const data = useHMSStore()
  // console.log("useHMSStore",useHMSStore)
  const getToken = () => {
    return fetch(`${baseURL}/token`, {
      method: "GET",
    }).then((response) => response.json());
  };

  const initToken = async () => {
    const token = await getToken();
    console.log("token",token)
    setToken(token?.token);
  };
  useEffect(() => {
    initToken();
  },[])
  //const { phoneNumber, setPhoneNumber,marketKey } = useContactStore();
  // const [phoneNumber,setPhoneNumber] = useState()
  //State
  const [userState, setUserState] = useState(USER_STATE.OFFLINE);
  const [connection, setConnection] = useState(null);
  const [callDevice, setDevice] = useState();
  //const { convo, setConvo, activeConvoId } = useConvoStore();
  // console.log("activeConvoId",activeConvoId)
  console.log("userState",userState)
  //Callback
  useEffect(() => {
    console.log('token',token)
    init();
  }, [token]);
  const [defaultDate, setDefaultDate] = useState(moment().add(12, "hours"));

  const [incomingCall, setIncomingCall] = useState(null);
  const [isCallActive, setIsCallActive] = useState(false);

  const [showPhoneGrid, setShowPhoneGrid] = useState(false);
  const togglePhoneGrid = () => {
    setShowPhoneGrid(!showPhoneGrid);
  };


  const [dialingCode, setDialingCode] = useState('1');
  const handleDialingCodeChange = (event) => {
    const inputDialingCode = event.target.innerHTML;

    // const inputDialingCode = event.target.value;
    let formattedDialingCode = inputDialingCode;

    // Remove non-digit characters from the input
    formattedDialingCode = formattedDialingCode.replace(/\D/g, '');

    setDialingCode(formattedDialingCode);
  };

  const getCountryCode = (dialingCode) => {
    const dialingCodeMappings = codeMappings;

    let countryCode = '';

    // Match the input dialing code with available dialing codes
    for (const code in dialingCodeMappings) {
      if (dialingCode.startsWith(code)) {
        countryCode = dialingCodeMappings[code];
        break;
      }
    }

    return countryCode;
  };
  const countryCode = getCountryCode(dialingCode);

  //Helpers
  const init = async () => {
    if (token) {
      try {
        console.log("Token connected successfully!!", token);
        const device = new Device(token, {
          logLevel: 1,
          edge: "ashburn",
        });
        device.register();
        setDevice(device);
        device.addListener("connect", (device) => {
          console.log("Connect event listener added .....");
          return device;
        });
        device.on("registered", () => {
          console.log("Agent registered");
          setUserState(USER_STATE.READY);
        });
        device.on("incoming", (call) => {
          console.log("incoming call TYG", call);
          handleIncomingCall(call);
          setUserState(USER_STATE.INCOMING);
        });
        device.on("connect", (connection) => {
          console.log("Call connect");
          setConnection(connection);
          setUserState(USER_STATE.ON_CALL);
        });

        device.on("disconnect", () => {
          console.log("Disconnect event");
          setUserState(USER_STATE.READY);
          setConnection(null);
        });

        return () => {
          console.log("token_destroyed")
          device.destroy();
          setDevice(undefined);
          setUserState(USER_STATE.OFFLINE);
        };
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  function handleIncomingCall(call) {
    console.log(`Incoming call from ${call.parameters.From}`);
    setIncomingCall(call); // Set the incoming call state
    //getRecordings(call?.parameters?.CallSid)
    //show incoming call div and incoming phone number

    // add event listener to call object
    call.on("cancel", handleDisconnectedIncomingCall);
    call.on("disconnect", handleDisconnectedIncomingCall);
    call.on("reject", handleDisconnectedIncomingCall);
  }
  
  async function handleCallUpdates(obj) {
    const { data, error } = await supabase
    .from("twilioRecordings")
    .upsert(obj)
    .select();
    if(error) {
      console.log("handleCallUpdateserror",error)
    }
  }
  async function initiateCallRecording(CallSid,obj) {
    const res = await axios.post("https://groupreportingemail-tyg.onrender.com/startrecordingbycallsid",{
      CallSid : CallSid
      })
    handleCallUpdates({
      id : CallSid,
      ...obj
    })
  }

  // ACCEPT INCOMING CALL
  function acceptIncomingCall(call) {
    if (call) {
      call.accept();
      setIsCallActive(true); // Set call as active
      initiateCallRecording(call?.parameters?.CallSid,{
        cid : window?.cid,
        category : "inbound"
      })
      // if (call?.parameters?.CallSid && activeConvoId) {
      //   const historyObj = {
      //     id: `inbound-call-${call.parameters?.CallSid}-${activeConvoId}`,
      //     timeStarted: new Date().toISOString(),
      //     stage: "inbound_started",
      //   };
      //   //addConvoHistory(activeConvoId, historyObj);
      // }

      // Rest of the code...
    }
  }

  function rejectIncomingCall(call) {
    call.reject();
    console.log("Rejected incoming call");
    setIncomingCall(null);
    setIsCallActive(false);
  }

  function sendDtmfDigits(call, digit = "1") {
    console.log("sendDigits() sent: " + digit, connection, incomingCall);
    call.sendDigits(digit);
  }

  // HANG UP INCOMING CALL
  function hangupIncomingCall(call) {
    if (call) {
      call.disconnect();
      setIsCallActive(false);
      setIncomingCall(null);

      // if (call?.parameters?.CallSid && activeConvoId) {
      //   let id = `outbound-call-${call.parameters?.CallSid}-${activeConvoId}`;
      //   const historyObj = {
      //     timeEnded: new Date().toISOString(),
      //     stage: "outbound_completed",
      //   };
      //   //updateConvoHistory(activeConvoId, id, historyObj);
      // }
    }
  }

  // HANDLE CANCELLED INCOMING CALL

  function handleDisconnectedIncomingCall() {
    console.log("Incoming call ended.");
    resetIncomingCallUI();
  }

  function resetIncomingCallUI() {
    // incomingPhoneNumberEl.innerHTML = "";
    // incomingCallAcceptButton.classList.remove("hide");
    // incomingCallRejectButton.classList.remove("hide");
    // incomingCallHangupButton.classList.add("hide");
    // incomingCallDiv.classList.add("hide");
  }

  const handleCall = async () => {

    if (userState === USER_STATE.ON_CALL) {
      callDevice?.disconnectAll();
      return;
    }
    console.log("step1",token)
    const params = {
      To: phoneNumber,
      statusCallback:
        "https://webhook.site/27c15af9-8954-4220-a45e-1b97883f1701",
      statusCallbackEvent: "initiated ringing answered completed",
      Record : true,
      //RecordingStatusCallbackEvent : 
    };
    callDevice?.emit("connect");
    callDevice
      ?.connect({
        params: params,
        rtcConstraints: {
          audio: true,
        },
      })
      .then((call) => {
        call.on("accept", (connection) => {
          console.log("CallSid", call?.parameters?.CallSid);
          console.log("connection",connection)
          initiateCallRecording(call?.parameters?.CallSid,{
            cid : window?.cid,
            category : "outbound"
          })
          // update call status to supabse
          // if (call?.parameters?.CallSid) {
          //   const historyObj = {
          //     id: `outbound-call-${call.parameters?.CallSid}-${activeConvoId}`,
          //     timeStarted: new Date().toISOString(),
          //     stage: "outbound_started",
          //   };
          //   //addConvoHistory(activeConvoId, historyObj);
          //   //updateCallsSupabase(call.parameters?.CallSid,phoneNumber,marketKey)
          // }
          setConnection(connection);
          setUserState(USER_STATE.ON_CALL);
          console.log("call accepted");
        });

        call.on("disconnect",async (connection) => {
          console.log("The call has been disconnected.");
          console.log("disconnected",connection)
          setUserState(USER_STATE.READY);
          setConnection(null);
          // if (call?.parameters?.CallSid) {
          //   // updateCallsSupabase(12,call.parameters?.CallSid,)
          //   let id = `outbound-call-${call.parameters?.CallSid}-${activeConvoId}`;
          //   const historyObj = {
          //     timeEnded: new Date().toISOString(),
          //     stage: "outbound_completed",
          //   };
          //   updateConvoHistory(activeConvoId, id, historyObj);
          // }
        });
        call.on("reject", () => {
          console.log("The call was rejected.");
        });
      });
  };

  function isEmpty(value) {
    for (let prop in value) {
      if (value.hasOwnProperty(prop)) return false;
    }
    return true;
  }

  const sequenceHandlerRef = useRef(null);

  const [isMuted, setIsMuted] = useState(false);
  const toggleMute = () => {
    if (connection) {
      if (isMuted) {
        connection.mute(false); // Unmute the call if it is currently muted
      } else {
        connection.mute(true); // Mute the call if it is currently unmuted
      }
      setIsMuted(!isMuted); // Toggle the mute state
    }
  };

  //Render Element
  return (
    <div className="phone" style={{ color: "white" }}>
      {/* <div className="user-state text-xs	">
        <i style={{ color: stateColor[userState] }} className="fas fa-stop"></i>{" "}
        {`Status - > ${userState}`}
      </div> */}
      <style jsx={true}>{`
        .phone-box {
          margin: 0 39%;
          margin-top: 20px;
        }
        .gird {
          display: grid;
          grid-template-columns: 30% 30% 30%;
          justify-items: center;
          column-gap: 10px;
          row-gap: 15px;
        }
      `}</style>

      <div>
        {true && (
          <div className="rounded-md bg-black border-1 border-white/20 mt-1">
            {/* Incoming Call UI */}
            {incomingCall && !isCallActive && (
              <div className="rounded-md bg-black p-4 border border-white/20">
                <div className="text-white text-center mb-2">
                  Incoming call from {incomingCall?.parameters?.From}
                </div>
                <div className="flex justify-center gap-4">
                  <button
                    onClick={() => acceptIncomingCall(incomingCall)}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => rejectIncomingCall(incomingCall)}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Decline
                  </button>
                </div>
              </div>
            )}

            {/* Call Active UI */}
            {isCallActive && (
              <div className="rounded-md bg-black p-4 border border-white/20  mt-1">
                <div className="flex justify-center items-center flex-col my-4">
                  <div className="flex gap-2">
                    <div className="text-white mb-2">Call in Progress - </div>
                    <Timer />
                  </div>
                  <button
                    onClick={() => hangupIncomingCall(incomingCall)}
                    className="mt-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Hangup
                  </button>
                </div>
              </div>
            )}

            
            <div className="flex flex-col">
              {userState === USER_STATE.ON_CALL ? (
                <>
                  <div className="flex justify-center items-center text-center">
                    <div className="flex items-center space-x-2">
                      {showPhoneGrid ? (
                        <>
                          <button
                            onClick={() => togglePhoneGrid()}
                            className="p-2 rounded-full text-purple-700 hover:bg-gray-100"
                          >
                            <ArrowDownIcon />
                            <span className="sr-only">Show phone grid</span>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => togglePhoneGrid()}
                            className="p-2 rounded-full text-purple-700 hover:bg-gray-100"
                          >
                            <ArrowUp size={20} />
                            <span className="sr-only">Hide phone grid</span>
                          </button>
                        </>
                      )}
                    </div>

                    <span className="text-black">Outbound Call - </span>
                    <Timer />

                    <div className="mute-button">
                      <button
                        onClick={toggleMute}
                        className={`px-4 ml-2 py-2 rounded ${isMuted ? "bg-red-500" : "bg-green-500"} text-white`}
                      >
                        {isMuted ? "Unmute" : "Mute"}
                      </button>
                    </div>
                  </div>
                  {showPhoneGrid && (
                    <>
                      <div className="flex text-black/40 mb-4 flex-col text-xs justify-center items-center text-center">
                        {/* {activeConvoId ? activeConvoId : "No Conversation "}{" "} */}
                        selected.{" "}
                        <span className="cursor-pointer hover:text-white">
                          {" "}
                          + Create a new conversation{" "}
                        </span>
                      </div>
                      <div className="gird text-black">
                        {numberList.map((value) => (
                          <div
                            key={value}
                            className="number"
                            onClick={() => {
                              sendDtmfDigits(connection, value);
                            }}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>


          </div>
        )}
      </div>

      <div style={{display: "flex"}}>
       
      <Box css={{ p: "$4 0", my: "$2", position: "relative" }}>
            <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '7px',
              border : '1px solid #2D3440',
              backgroundColor : '#1E232A',
              padding : '10px'
            }}
            styleName="input_design"
          >
            <div style={{ width: '25px' }}>
              {countryCode && (
                <Flag
                  style={{ width: '25px' }}
                  default="us"
                  code={getCountryCode(dialingCode)}
                />
              )}
            </div>
            {/* <p style={{ fontSize: '16px',color : '#FFFFFFE6' }}>+</p> */}
            {/* <span
              class="input"
              role="textbox"
              contentEditable
              style={{
                fontSize: '18px',
                color : '#FFFFFFE6'
              }}
              tabIndex={4}
              value={dialingCode}
              onInput={handleDialingCodeChange}
              // dangerouslySetInnerHTML={{ __html: content }}
            >
              1
            </span> */}
            <input
              type="text"
              name="phone"
              for="phone"
              styleName="input_design"
              autoComplete
              value={phoneNumber}
              onPaste={(event) => {
                let pastedText = event.clipboardData.getData('text/plain');
                pastedText = new AsYouType(countryCode).input(pastedText);
                setPhoneNumber(pastedText);
                // const parsedNumber = parsePhoneNumber(pastedText);
                // if (parsedNumber?.country) {
                //   setDialingCode(parsedNumber.country);
                // }
              }}
              onChange={(e) => {
                const val = new AsYouType(countryCode).input(e.target.value);
                setPhoneNumber(val);
                // const parsedNumber = parsePhoneNumber(val);
                // if (parsedNumber?.country) {
                //   setDialingCode(parsedNumber.country);
                // }
              }}
              onKeyDown={(e) => {
                if (
                  e.key === 'Backspace' &&
                  phoneNumber.length == 5 &&
                  phoneNumber.includes('(')
                ) {
                   (phoneNumber.substring(1, 4));
                }
              }}
              placeholder="Phone number to share "
              required
              minLength={10}
              maxLength={15}
              style={{
                marginLeft: '0',
                width: '100%',
                background: 'transparent',
                height: '100%',
                border: 'none',
                outline: 'none',
                color: 'white',
                //border : '1px solid #2D3440'
              }}
              tabIndex={2}
            />

<div
            style={{
              position: "absolute",
              right: "-2rem",
              top: "12px",
              backgroundColor: userState === USER_STATE.ON_CALL ? "#EF4444" : "#60A5FA",
              borderColor: "#3B82F6",
              borderWidth: "1px",
              borderRadius: "0.375rem",
              display: "flex",
              gap: "0.5rem",
              padding: "0.5rem 0.75rem",
              textAlign: "center",
              cursor: "pointer",
              justifyContent: "center"
            }}
            onClick={() => {
              console.log("clicked")
              handleCall();
            }}
          >
            {userState === USER_STATE.ON_CALL ? (
                    <i className="material-icons">
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_513_184)">
                          <path
                            d="M3.24028 9.277C3.63682 8.85013 4.36639 8.41079 5.76117 8.03372C7.45981 7.57756 8.87615 7.31991 11.0955 7.35847C13.2377 7.39782 14.4682 7.59166 16.581 8.22581C18.9669 8.94137 19.6977 9.87607 19.9219 10.3704C20.1906 10.9605 20.2022 11.474 20.1384 12.0646C20.0995 12.3975 20.0291 12.7226 19.9281 13.036C19.9183 13.0676 19.9095 13.0973 19.9016 13.1238C19.8556 13.2827 19.7856 13.5232 19.5046 13.6492C19.3177 13.7338 19.0837 13.7471 18.6898 13.7421C17.8827 13.7326 16.4029 13.3695 15.7377 13.1248C15.29 12.9594 14.9941 12.8504 14.7549 12.6286C14.4751 12.3686 14.3763 12.0422 14.2853 11.6729C14.2682 11.6036 14.2528 11.5364 14.2378 11.4711C14.148 11.0777 14.1113 10.9755 13.9628 10.8825C13.6641 10.6958 12.5718 10.3586 11.3501 10.3371C10.1284 10.3156 9.13856 10.6175 8.87283 10.7947C8.73543 10.8863 8.71803 10.9903 8.70098 11.3949C8.69854 11.4529 8.69626 11.5127 8.69255 11.574C8.66982 11.9872 8.63588 12.2987 8.40877 12.5432L8.40755 12.5445C8.20989 12.7573 7.93753 12.8436 7.48709 12.9874C6.89951 13.1748 5.46533 13.5174 4.65449 13.4987C4.26027 13.4897 4.02309 13.4686 3.81982 13.3772C3.51377 13.2395 3.40006 12.9958 3.32275 12.8339C3.30998 12.807 3.29607 12.7774 3.28012 12.745C3.12181 12.4265 2.99196 12.0974 2.8922 11.7618C2.71953 11.1683 2.63745 10.6533 2.79813 10.0701C2.87895 9.76905 3.03014 9.49785 3.24028 9.277Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_513_184">
                            <rect
                              width="15.2821"
                              height="17.08"
                              fill="white"
                              transform="translate(22.915 11.6246) rotate(132.89)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </i>
                  ) : (
                    <i className="material-icons flex text-center">
                      <svg
                        width="20"
                        height="22"
                        viewBox="0 0 20 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.0316 20.625C14.2812 20.625 13.227 20.3216 11.6484 19.3359C9.72882 18.1328 8.24404 17.022 6.33483 14.8938C4.49405 12.8377 3.59826 11.5066 2.34455 8.9568C0.928209 6.0779 1.16965 4.56884 1.43954 3.92387C1.76094 3.15301 2.23536 2.69196 2.84857 2.23434C3.19687 1.9793 3.56546 1.76066 3.94966 1.58122C3.9881 1.56274 4.02386 1.54512 4.05577 1.52923C4.24607 1.43341 4.53442 1.2886 4.89965 1.44329C5.1434 1.54555 5.361 1.75481 5.70163 2.13079C6.40019 2.90079 7.3548 4.61567 7.70696 5.45786C7.9434 6.02548 8.09987 6.40016 8.10026 6.8204C8.10026 7.31239 7.87881 7.6918 7.61008 8.1013C7.55971 8.17821 7.50973 8.25169 7.46129 8.32301C7.16872 8.7527 7.10451 8.87688 7.1468 9.0986C7.23254 9.54419 7.87189 10.8706 8.92261 12.0424C9.97333 13.2141 11.1259 13.8836 11.5262 13.979C11.733 14.0284 11.8464 13.9536 12.2432 13.615C12.3001 13.5665 12.3585 13.5162 12.4196 13.4659C12.8295 13.1252 13.1532 12.8841 13.583 12.8841H13.5853C13.9594 12.8841 14.2796 13.0655 14.8102 13.3645C15.5022 13.7547 17.0827 14.8079 17.7759 15.5895C18.1131 15.9693 18.3011 16.2116 18.3929 16.4836C18.5314 16.8931 18.401 17.2141 18.3161 17.429C18.3018 17.4646 18.2861 17.5037 18.2695 17.5471C18.1077 17.9758 17.9109 18.3868 17.6817 18.7752C17.273 19.4584 16.859 19.9873 16.1677 20.347C15.8127 20.5346 15.4243 20.6297 15.0316 20.625Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                  )}
          </div>
          </div>
          </Box>

      </div>

   
    </div>
  );
};

export default Phone;
