import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDebounce, useMeasure } from "react-use";
import Flag from 'react-world-flags';
import { AsYouType } from 'libphonenumber-js';// replace with your actual import paths
import { User, Mail, PhoneOutgoing,Users,BookUser } from 'lucide-react';// using react-icons for illustrative purposes
import { FixedSizeList } from "react-window";
import {
  selectAudioTrackByPeerID,
  selectLocalPeerID,
  selectPeerCount,
  selectPeerMetadata,
  selectPermissions,
  useHMSActions,
  useHMSStore,
  useParticipants,
} from "@100mslive/react-sdk";
import {
  ChangeRoleIcon,
  CrossIcon,
  HandRaiseIcon,
  PeopleIcon,
  RemoveUserIcon,
  SearchIcon,
  ShareIcon,
  SpeakerIcon,
  VerticalMenuIcon,
  
} from "@100mslive/react-icons";
import SvgShareIcon from "@100mslive/react-icons/dist/ShareIcon";
import Phone from "./Phone";

import {
  Avatar,
  Box,
  Button,
  Dropdown,
  Flex,
  Input,
  Slider,
  Text,
  textEllipsis,
} from "@100mslive/react-ui";
import IconButton from "../../IconButton";
import { ConnectionIndicator } from "../Connection/ConnectionIndicator";
import { RoleChangeModal } from "../RoleChangeModal";
import { ToastManager } from "../Toast/ToastManager";
import { ParticipantFilter } from "./ParticipantFilter";
import {
  useIsSidepaneTypeOpen,
  useSidepaneToggle,
} from "../AppData/useSidepane";
import { isInternalRole,codeMappings } from "../../common/utils";
import { getCommunitiesByUserId } from "../../utils/api";
import { SIDE_PANE_OPTIONS } from "../../common/constants";
import emailsvg from './email.svg'
import { getLeadsByuuid } from "../../utils/api";

export const ParticipantList = () => {
  const [filter, setFilter] = useState();
  const { participants, isConnected, peerCount, rolesWithParticipants } =
    useParticipants(filter);
  const [selectedPeerId, setSelectedPeerId] = useState(null);
  const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
  const onSearch = useCallback(value => {
    setFilter(filterValue => {
      if (!filterValue) {
        filterValue = {};
      }
      filterValue.search = value;
      return { ...filterValue };
    });
  }, []);
  if (peerCount === 0) {
    return null;
  }
  const [leads,setleads] = useState([])
  const [activeTab,setactiveTab] = useState("participants")
  const fetchLeads = async (uuid) => {
    const res = await getLeadsByuuid(uuid)
    console.log("res",res)
    setleads(res?.data)
  }
  useEffect(() => {
    fetchLeads(window.uuid)
  }, []);

  const [participantOrLeadsTab,setParticipantsOrLeadsTab] = useState("participants")



  return (
    <Fragment>
      <Flex direction="column" css={{ size: "100%" }}>
        <Flex align="center" css={{ w: "100%", mb: "$10" }}>
          <Text css={{ fontWeight: "$semiBold", mr: "$4" }}>Participants</Text>
          <ParticipantFilter
            selection={filter}
            onSelection={setFilter}
            isConnected={isConnected}
            roles={rolesWithParticipants}
          />
          <IconButton
            onClick={toggleSidepane}
            css={{ w: "$11", h: "$11", ml: "auto" }}
          >
            <CrossIcon />
          </IconButton>
        </Flex>
        {!filter?.search && participants.length === 0 ? null : (
          <ParticipantSearch onSearch={onSearch} />
        )}
        {participants.length === 0 && (
          <Flex align="center" justify="center" css={{ w: "100%", p: "$8 0" }}>
            <Text variant="sm">
              {!filter ? "No participants" : "No matching participants"}
            </Text>
          </Flex>
        )}
         <Flex direction="row" css={{ w: "100%",  mb: "$10" }}>
         <IconButton
          css={{
            w: "auto",
            p: "$4",
            h: "auto",
            mr: '10px'
          }}
          onClick={() => {
            setactiveTab("participants")
          }}
          active={!(activeTab == "participants")}
          data-testid="participant_list"
        >
          <Users />
          <Text variant="sm" css={{ mx: "$4", c: "inherit" }}>
            Participants
          </Text>
        </IconButton>
        <IconButton
          css={{
            w: "auto",
            p: "$4",
            h: "auto",
          }}
          onClick={() => {
            setactiveTab("leads")
          }}
          active={!(activeTab == "leads")}
          data-testid="participant_list"
        >
          <BookUser />
          <Text variant="sm" css={{ mx: "$4", c: "inherit" }}>
            Leads
          </Text>
        </IconButton>
        </Flex>
        {
          activeTab == "participants" ?
            <VirtualizedParticipants
            participants={participants}
            isConnected={isConnected}
            setSelectedPeerId={setSelectedPeerId}
            />
          :
          <div style={{overflow: 'auto'}}>
              {leads?.length > 0 && (
                leads?.map((item) => <Leaditem name={item?.name} email={item?.email} phone={item?.phone}/>)
              )}
          </div>
        }
        
        
      </Flex>
      {selectedPeerId && (
        <RoleChangeModal
          peerId={selectedPeerId}
          onOpenChange={value => {
            !value && setSelectedPeerId(null);
          }}
        />
      )}
    </Fragment>
  );
};

export const ToursList = () => {
  const [filter, setFilter] = useState();
  const { participants, isConnected, peerCount, rolesWithParticipants } =
    useParticipants(filter);
  const [selectedPeerId, setSelectedPeerId] = useState(null);
  const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.TOURS);
  const onSearch = useCallback(value => {
    setFilter(filterValue => {
      if (!filterValue) {
        filterValue = {};
      }
      filterValue.search = value;
      return { ...filterValue };
    });
  }, []);
  const [availableCommunities, setAvailableCommunities] = useState([]);

  async function fetchCommunities() {
    const newCommunityList = await getCommunitiesByUserId(window.uid);
    console.log("Tour User List Communities TYG: ", newCommunityList);
    setAvailableCommunities(newCommunityList);
  }
  useEffect(() => {
    fetchCommunities();
  }, []);

  var getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return (
    <Fragment>
      <Flex direction="column" css={{ size: "100%" }}>
        <Flex align="center" css={{ w: "100%", mb: "$10" }}>
          <Text css={{ fontWeight: "$semiBold", mr: "$4" }}>Communities</Text>
          {/* <ParticipantFilter
            selection={filter}
            onSelection={setFilter}
            isConnected={isConnected}
            roles={rolesWithParticipants}
          /> */}
          <IconButton
            onClick={toggleSidepane}
            css={{ w: "$11", h: "$11", ml: "auto" }}
          >
            <CrossIcon />
          </IconButton>
        </Flex>

        {!filter?.search && participants.length === 0 ? null : (
          <ParticipantSearch onSearch={onSearch} />
        )}
        <div></div>

        <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
          {availableCommunities.map((community, idx) => {
            if (
              !community?.name
                ?.toLowerCase()
                .includes(filter?.search?.toLowerCase())
            ) {
              return <></>;
            }
            return (
              <div
                onClick={() => {
                  window.getScreensVideos(community?.id, true);
                  window.updatecommunityName(community?.name);
                  toggleSidepane();
                }}
                style={{
                  color: "white",
                  cursor: "pointer",
                  marginBottom: "15px",
                  fontSize: "15px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#367ff4",
                    color: "white",
                    fontWeight: "800",
                    alignContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    borderRadius: "10px",
                    marginRight: "10px",
                  }}
                >
                  {getInitials(community?.name)}
                </div>
                <div>
                  {community?.name} <br />
                  <span style={{ color: "gray" }}>{community?.url}</span>
                </div>
              </div>
            );
          })}
        </div>

        {participants.length === 0 && (
          <Flex align="center" justify="center" css={{ w: "100%", p: "$8 0" }}>
            <Text variant="sm">
              {!filter
                ? "No additional communities"
                : "No additional communities"}
            </Text>
          </Flex>
        )}
      </Flex>
      {selectedPeerId && (
        <RoleChangeModal
          peerId={selectedPeerId}
          onOpenChange={value => {
            !value && setSelectedPeerId(null);
          }}
        />
      )}
    </Fragment>
  );
};

export const CommunityAvailabilityList = ({ communtiyname }) => {
  // const peerCount = useHMSStore(selectPeerCount);
  const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.TOURS);
  const isParticipantsOpen = useIsSidepaneTypeOpen(SIDE_PANE_OPTIONS.TOURS);
  useEffect(() => {
    if (isParticipantsOpen && !window.location.pathname.includes("/meeting")) {
      toggleSidepane();
    }
  }, [isParticipantsOpen, toggleSidepane]);

  return (
    <IconButton
      css={{
        w: "auto",
        p: "$4",
        h: "auto",
      }}
      onClick={() => {
        toggleSidepane();
      }}
      active={!isParticipantsOpen}
      data-testid="participant_list"
    >
      🏢
      <Text variant="sm" css={{ mx: "$4", c: "inherit" }}>
        {communtiyname ? communtiyname : ""}
      </Text>
    </IconButton>
  );
};

export const ParticipantCount = () => {
  const peerCount = useHMSStore(selectPeerCount);
  const toggleSidepane = useSidepaneToggle(SIDE_PANE_OPTIONS.PARTICIPANTS);
  const isParticipantsOpen = useIsSidepaneTypeOpen(
    SIDE_PANE_OPTIONS.PARTICIPANTS
  );
  useEffect(() => {
    if (isParticipantsOpen && peerCount === 0) {
      toggleSidepane();
    }
  }, [isParticipantsOpen, peerCount, toggleSidepane]);

  if (peerCount === 0) {
    return null;
  }
  return (
    <IconButton
      css={{
        w: "auto",
        p: "$4",
        h: "auto",
      }}
      onClick={() => {
        if (peerCount > 0) {
          toggleSidepane();
        }
      }}
      active={!isParticipantsOpen}
      data-testid="participant_list"
    >
      <PeopleIcon />
      <Text variant="sm" css={{ mx: "$4", c: "inherit" }}>
        {peerCount}
      </Text>
    </IconButton>
  );
};

function itemKey(index, data) {
  return data.participants[index].id;
}

const VirtualizedParticipants = ({
  participants,
  isConnected,
  setSelectedPeerId,
}) => {
  const [ref, { width, height }] = useMeasure();
  return (
    <Box
      ref={ref}
      css={{
        flex: "1 1 0",
        mr: "-$10",
      }}
    >
      <FixedSizeList
        itemSize={68}
        itemData={{ participants, isConnected, setSelectedPeerId }}
        itemKey={itemKey}
        itemCount={participants.length}
        width={width}
        height={height}
      >
        {VirtualisedParticipantListItem}
      </FixedSizeList>
    </Box>
  );
};

const VirtualisedParticipantListItem = React.memo(({ style, index, data }) => {
  return (
    <div style={style} key={data.participants[index].id}>
      <Participant
        peer={data.participants[index]}
        isConnected={data.isConnected}
        setSelectedPeerId={data.setSelectedPeerId}
      />
    </div>
  );
});

const Participant = ({ peer, isConnected, setSelectedPeerId }) => {
  return (
    <Fragment>
      <Flex
        key={peer.id}
        css={{ w: "100%",
         //py: "$4", 
         pr: "$10" }}
        align="center"
        data-testid={"participant_" + peer.name}
      >
        <Avatar
          name={peer.name}
          css={{
            position: "unset",
            transform: "unset",
            mr: "$8",
            fontSize: "$sm",
            size: "$12",
            p: "$4",
          }}
        />
        <Flex direction="column" css={{ flex: "1 1 0" }}>
          <Text
            variant="md"
            css={{ ...textEllipsis(150), fontWeight: "$semiBold" }}
          >
            {peer.name}
          </Text>
          <Text variant="sub2">{peer.roleName}</Text>
        </Flex>
        {isConnected && (
          <ParticipantActions
            peerId={peer.id}
            role={peer.roleName}
            onSettings={() => {
              setSelectedPeerId(peer.id);
            }}
          />
        )}
      </Flex>
    </Fragment>
  );
};

/**
 * shows settings to change for a participant like changing their role
 */
const ParticipantActions = React.memo(({ onSettings, peerId, role }) => {
  const isHandRaised = useHMSStore(selectPeerMetadata(peerId))?.isHandRaised;
  const canChangeRole = useHMSStore(selectPermissions)?.changeRole;
  const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId));
  const localPeerId = useHMSStore(selectLocalPeerID);
  const canChangeVolume = peerId !== localPeerId && audioTrack;
  const shouldShowMoreActions = canChangeRole || canChangeVolume;

  return (
    <Flex align="center" css={{ flexShrink: 0 }}>
      <ConnectionIndicator peerId={peerId} />
      {isHandRaised && <HandRaiseIcon />}
      {shouldShowMoreActions && !isInternalRole(role) && (
        <ParticipantMoreActions
          onRoleChange={onSettings}
          peerId={peerId}
          role={role}
        />
      )}
    </Flex>
  );
});

const ParticipantMoreActions = ({ onRoleChange, peerId }) => {
  const { changeRole: canChangeRole, removeOthers: canRemoveOthers } =
    useHMSStore(selectPermissions);
  const localPeerId = useHMSStore(selectLocalPeerID);
  const isLocal = localPeerId === peerId;
  const actions = useHMSActions();
  const [open, setOpen] = useState(false);
  return (
    <Dropdown.Root open={open} onOpenChange={value => setOpen(value)}>
      <Dropdown.Trigger
        asChild
        data-testid="participant_more_actions"
        css={{ p: "$2", r: "$0" }}
        tabIndex={0}
      >
        <Text>
          <VerticalMenuIcon />
        </Text>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content align="end" sideOffset={8} css={{ w: "$64" }}>
          {canChangeRole && (
            <Dropdown.Item onClick={() => onRoleChange(peerId)}>
              <ChangeRoleIcon />
              <Text css={{ ml: "$4" }}>Change Role</Text>
            </Dropdown.Item>
          )}
          <ParticipantVolume peerId={peerId} />
          {!isLocal && canRemoveOthers && (
            <Dropdown.Item
              onClick={async () => {
                try {
                  await actions.removePeer(peerId, "");
                } catch (error) {
                  // TODO: Toast here
                }
              }}
            >
              <RemoveUserIcon />
              <Text css={{ ml: "$4", color: "$error" }}>
                Remove Participant
              </Text>
            </Dropdown.Item>
          )}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const ParticipantVolume = ({ peerId }) => {
  const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId));
  const localPeerId = useHMSStore(selectLocalPeerID);
  const hmsActions = useHMSActions();
  // No volume control for local peer or non audio publishing role
  if (peerId === localPeerId || !audioTrack) {
    return null;
  }

  return (
    <Dropdown.Item css={{ h: "auto" }}>
      <Flex direction="column" css={{ w: "100%" }}>
        <Flex align="center">
          <SpeakerIcon />
          <Text css={{ ml: "$4" }}>
            Volume{audioTrack.volume ? `(${audioTrack.volume})` : ""}
          </Text>
        </Flex>
        <Slider
          css={{ my: "0.5rem" }}
          step={5}
          value={[audioTrack.volume]}
          onValueChange={e => {
            hmsActions.setVolume(e[0], audioTrack?.id);
          }}
        />
      </Flex>
    </Dropdown.Item>
  );
};

const Leaditem = ({ name,phone,email }) => {
  return ( 
  <Flex direction="row" css={{ 
    //backgroundColor: "rgb(224, 224, 224)",
  // border: '1px solid #e0e0e0',
  // border: '1px solid #e0e0e0',
  borderBottom: '0.6px solid rgb(99 99 99)', // Add bottom border
  maxWidth: '100%', // Maximum width
  width: 'auto', // Auto width based on content up to max width
  flex: '1 1 0', '@media(max-width: 600px)': { flexDirection: 'column' } }}>
    <FlexItem label="Name" value={name} icon={<User color="white" size={20} fontSize={20}/>} />
      <FlexItem label="Email" value={email} icon={<Mail color="white"  size={20} fontSize={20}/>} />
      <FlexItem label="Phone" value={phone} icon={<PhoneOutgoing color="white"  size={20} fontSize={20}/>} />
  </Flex>)
};

const FlexItem = ({ label, value, icon }) => (
  <Flex direction="column" css={{ 
      flex: '1 1 0', 
      margin: '0 10px', 
    }}>
    {/* <Text variant="md" css={{ fontWeight: '$semiBold',fontSize: '12px' }}>{label}</Text> */}
    <Flex align="center">
      {/* {icon} */}
      <Text variant="sub2" css={{ marginLeft: '8px',fontSize: '14px' }}>{value}</Text>
    </Flex>
  </Flex>
);

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + match[2] + match[3];
  }
  return false;
}



export const ParticipantSearch = ({ onSearch, placeholder }) => {
  const [value, setValue] = React.useState("");

  const [showShare, setShowShare] = React.useState(true);
  const [sharePhone, setSharePhone] = React.useState("");
  const [shareEmail, setShareEmail] = React.useState("");
  const [extramessage, setextramessage] = React.useState("");
  const [shareError, setShareError] = React.useState("");
  const [shareLoading, setShareLoading] = React.useState(false);
  const [shareMessage, setShareMessage] = React.useState("");

  console.log("extramessage",extramessage)
  async function sendSMSInvite(phoneNumber,dialingCode) {
    if(!phoneNumber) {
      return
    }
    console.log("sendSMSInvite",`${dialingCode}` + `${phoneNumber}`)
    // Construct the parameters
    const params = {
      to: `+${dialingCode}` + `${phoneNumber}`,
      message: `Hi, I am inviting you for a meeting. Please join the meeting through this link ${window.location.href} ${extramessage && `\n${extramessage}`}`,
    };
  
    try {
      // Make the API call
      const response = await fetch("https://api.leasemagnets.com/send_sms", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // add any other necessary headers
          // 'Authorization': 'Bearer your_token'  // if authentication is required
        },
        body: JSON.stringify(params),
      });
  
      // Check if the response is successful
      if (response.ok) {
        ToastManager.addToast({ title: "Invite SMS sent" });
      }
  
    } catch (error) {
      console.error(`Failed to send SMS: ${error.message}`);
    }
  }

  const sendEmail = email => {
    if(!email) {
      return
    }
    let data = {
      to: email,
      subject: `Meeting invitation`,
      message: `Hi there👋, <br/><br/> I am inviting you for a meeting. Please join the meeting through this link ${window.location.href} ${extramessage && `<br/><br/>${extramessage}`}`,
    };
  
    fetch("https://api.leasemagnets.com/email/plain", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // add any other necessary headers
        // 'Authorization': 'Bearer your_token'  // if required
      },
      body: JSON.stringify(data),
    })
      .then(response => { 
        if (response.ok) {
          ToastManager.addToast({ title: "Invite Email sent" });
      }}) // assuming server responds with json
      .catch(error => {
        console.error(`Email Sent Failed ${error.message}`);
      });
  };

  
  useDebounce(
    () => {
      onSearch(value);
    },
    300,
    [value, onSearch]
  );
  const [dialingCode, setDialingCode] = useState('1');
  const handleDialingCodeChange = (event) => {
    const inputDialingCode = event.target.innerHTML;

    // const inputDialingCode = event.target.value;
    let formattedDialingCode = inputDialingCode;

    // Remove non-digit characters from the input
    formattedDialingCode = formattedDialingCode.replace(/\D/g, '');

    setDialingCode(formattedDialingCode);
  };

  const getCountryCode = (dialingCode) => {
    const dialingCodeMappings = codeMappings;

    let countryCode = '';

    // Match the input dialing code with available dialing codes
    for (const code in dialingCodeMappings) {
      if (dialingCode.startsWith(code)) {
        countryCode = dialingCodeMappings[code];
        break;
      }
    }

    return countryCode;
  };
  const countryCode = getCountryCode(dialingCode);

  const [phoneNumber,setPhoneNumber] = useState("")

  return (
    <>
      <div>
        <Box css={{ p: "$4 0", my: "$8", position: "relative" }}>
          <Box
            css={{
              position: "absolute",
              left: "$4",
              top: "$2",
              transform: "translateY(50%)",
              color: "$textMedEmp",
            }}
          >
            <SearchIcon />
          </Box>
          <Input
            type="text"
            placeholder={placeholder || "Find what you are looking for"}
            css={{ w: "100%", pl: "$14" }}
            value={value}
            onKeyDown={event => {
              event.stopPropagation();
            }}
            onChange={event => {
              setValue(event.currentTarget.value);
            }}
            autoComplete="off"
            aria-autocomplete="none"
          />
          <Box
            css={{
              position: "absolute",
              right: "$4",
              top: "$2",
              transform: "translateY(50%)",
              color: "$textMedEmp",
            }}
            onClick={() => setShowShare(!showShare)}
          >
            <SvgShareIcon
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </div>
      <div>
        {showShare && (
          <>
          
            <Box css={{ p: "$4 0", my: "$2", position: "relative" }}>
              <div style={{'display' : 'flex',flexDirection : 'row'}}>
                <img 
                style={{width : '25px',color : '#FFFFFFE6',position : 'absolute',marginTop : '9px',marginLeft : '8px'}}
                src={emailsvg} />
                <Input
                  type="text"
                  placeholder="Email to Share"
                  css={{ w: "100%", pl: "$14" }}
                  value={shareEmail}
                  onKeyDown={event => {
                    event.stopPropagation();
                  }}
                  onChange={event => {
                    setShareEmail(event.currentTarget.value);
                  }}
                  autoComplete="off"
                  aria-autocomplete="none"
                />
              </div>
            </Box>

            <Box css={{ p: "$4 0", my: "$2", position: "relative" }}>
              <div style={{'display' : 'flex',flexDirection : 'row'}}>
                <Phone phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
              </div>
            </Box>

            <textarea
              placeholder="Optional: Send Extra Message"
              onChange={(e) => {setextramessage(e.target.value)}}
              style={{
                backgroundColor: "#66000000",
                borderRadius: "10px",
                width: "100%",
                minWidth: "200px",
                color: "white",
                backgroundColor : '#1E232A',
                padding : '10px'
              }}
            />
            <Button
              style={{ backgroundColor: "black", marginBottom:"10px" }}
              onClick={() => {
                console.log("inviteTYG: ");
                sendEmail(shareEmail);
                sendSMSInvite(phoneNumber,dialingCode);
              }}
            >
              Invite
            </Button>
          </>
        )}
      </div>
    </>
  );
};
