const { createClient } = require("@supabase/supabase-js");

const supabaseUrl = "https://tkweddqlriikqgylsuxz.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRrd2VkZHFscmlpa3FneWxzdXh6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzIwNzc0MjIsImV4cCI6MTk4NzY1MzQyMn0.IWdx6ELEUykw11jBQUW2ZT30jjX2-TyHT8INgLe07Ew";
export const supabase = createClient(supabaseUrl, supabaseKey);

export async function getSupabaseTable(table_name, column_name, filter) {
  let supabaseResponse = {};
  if (filter) {
    supabaseResponse = await supabase
      .from(table_name)
      .select(column_name ? column_name : "*")
      .eq(filter?.column_name, filter?.column_value);
    //.order("time", { ascending: false }); // If column_name then select that column otherwise select all columns
    console.log("supabaseResponse", supabaseResponse);
  } else {
    supabaseResponse = await supabase
      .from(table_name)
      .select(column_name ? column_name : "*")
      .order("time", { ascending: false });
  }
  return supabaseResponse;
}
